.whitebox{
background: #fff;
border-radius: 20px;
padding: 25px 20px;
margin-bottom: 20px;
position: relative;
}
.custom-tbl {
    border-collapse: collapse;
    width: 100%;
}
tr{
font-size: 14px;
color: #3f2783;
padding: 14px 2px;
border-top: 1px solid rgba(63, 39, 131, 0.1);
}
td{
    padding: 23px 0px;
}
.circle_ {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #3f2783;
    display: block;
    margin-left: 13px;
    position: relative;
}
.circle_::before {
    background: rgba(63, 39, 131, .7);
    bottom: 0;
    content: "";
    height: 96px;
    left: 0.39rem;
    position: absolute;
    width: 1px;
    z-index: 0;
    top: 0;
}