.input_box_main {
    border: 2px solid #3F2783;
    border-radius: 20px;
    padding: 25px;
    max-width: 420px;
}
.input_box_main .title_txt{
    font-size: 24px;
    font-weight: 600;
    margin: 0;
}
.number_box_row {
    display: flex;
    justify-content: space-between;
}
.number_box_col {
    text-align: center;
}
label.requiredFieldRightF {
    font-size: 14px;
    line-height: 28px;
    font-weight: 600;
    display: block;
    margin-bottom: 10px;
    color: #000;
}
.number_box_col span.qty_minus, .number_box_col span.qty_plus {
    font-size: 18px;
    background-color: transparent;
    width: 25px;
    height: 25px;
    text-align: center;
    border: 1.83px solid #3F2783;
    border-radius: 50%;
    color: #3F2783;
    display: inline-block;
    vertical-align: middle;
    line-height: 24px;
    cursor: default;
}
.number_box_col input[type="text"], .number_box_col input[type="number"] {
    width: calc(100% - 60px);
    border: 0;
    background: transparent;
    text-align: center;
    color: #3F2783;
    font-size: 18px;
    height: 25px;
    display: inline-block;
    vertical-align: middle;
    text-decoration: underline;
    /* padding: 0 0 0 16px; */
}
.ValidationErrors {
    color: #ff0000;
}
/* Chrome, Safari, Edge, Opera */
.number_box_col input[type="number"]::-webkit-outer-spin-button,
.number_box_col input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
.number_box_col input[type="number"] {
  -moz-appearance: textfield;
}

span.degree_txt {
    color: #3F2783;
    font-size: 18px;
    display: block;
    line-height: 24px;
    margin-top: 5px;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 8px;
}
.degree_box{
    max-width: 120px;
    margin: 0 auto;
}