.custom-dropdown {
    position: relative;
    width: 100%;
}
.selected-option.form-select {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.selected-option {
    padding: 7px !important;
    padding-right: 39px !important;
    padding-left: 14px !important;
    /* border: 1px solid #ccc; */
    cursor: pointer;
    /* width: 334px; */

    /* border: 2px solid rgba(63, 39, 131, 0.7); */
    /* border-radius: 20px; */
    /* color: #3f2783; */
    /* padding: 0px 26px 0px 14px;
    height: 40px;
    margin-bottom: 12px;
    width: 100%; */
    /* box-shadow: none; */
}
ul.dropoptions {
    width: 100%;
}
.dropoptions {
    list-style: none;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 100%;
    left: 0;
    /* width: 333px;
     */
    /* max-height: 128px; */
    overflow-y: auto;
    border: 1px solid #ccc;
    display: none;
    margin-top: 3px;
    z-index: 94;
    background-color: white;
    cursor: context-menu;
    box-shadow: 3px 7px 13px #e9e9e9;
    border-radius: 12px
}

.active_li {
    color: white !important;
    background-color: rgb(37, 114, 247);
}

.dropoptions li {
    padding: 3px 10px;
    color: #3f2783
}

/* style={{color:"#3f2783"}} */
.dropoptions li:hover {
    background-color: rgb(37, 114, 247);
    color: white;
}

.custom-dropdown.open .dropoptions {
    display: block;
}

/* .select-dropdownerr::after{
    content: '▼';
    right: 10px;
    top: 50%;
}
.select-dropdownerr:focus {
    outline: none;
  }
  .select-dropdownerr{
      text-align: end
  } */

.dropoptions::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.dropoptions {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.aradrop{
    padding-right: 42px !important;
}