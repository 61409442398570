.range-slider {
    width: 100%;
    position: relative;
    /* margin: 0 6px; */
}

.range-slider__range {
    -webkit-appearance: none;
    width: 100%;
    height: 10px;
    border-radius: 5px;
    background: #d7dcdf00 !important;
    outline: none;
    padding: 0;
    margin: 0;
    position: relative;
    z-index: 4;
}

input#painTodayRatingR {
    background: transparent;
}

input#painTodayRatingL {
    background: transparent;
}

.range-slider__range::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #fff;
    border: 2px solid #3f2783;
    cursor: pointer;
    -webkit-transition: background 0.15s ease-in-out;
    transition: background 0.15s ease-in-out;
}

.range-slider__range::-webkit-slider-thumb:hover {
    background: #fff;
}

.range-slider__range:active::-webkit-slider-thumb {
    background: #fff;
}

.range-slider__range::-moz-range-thumb {
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #fff;
    border: 2px solid #3f2783;
    cursor: pointer;
    -moz-transition: background 0.15s ease-in-out;
    transition: background 0.15s ease-in-out;
}
input[type="range"]::-moz-range-track {
    background: transparent;
    height: 0;
}

.range-slider__range::-moz-range-thumb:hover {
    background: #fff;
}

.range-slider__range:active::-moz-range-thumb {
    background: #fff;
}

.range-slider__range:focus::-webkit-slider-thumb {
    box-shadow: 0 0 0 0px #fff, 0 0 0 1px #3f2783;
}

.range-slider__value {
    display: inline-block;
    position: relative;
    width: 60px;
    color: #fff;
    line-height: 20px;
    text-align: center;
    border-radius: 3px;
    background: red;
    padding: 5px 10px;
    margin-left: 8px;
}

.range-slider__value:after {
    position: absolute;
    top: 8px;
    left: -7px;
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-right: 7px solid red;
    border-bottom: 7px solid transparent;
    content: "";
}

.range-slider ::-moz-range-track {
    background: #d7dcdf;
    border: 0;
}

.range-slider input::-moz-focus-inner,
.range-slider input::-moz-focus-outer {
    border: 0;
}

.range_tootip {
    position: absolute;
    line-height: 18px;
    color: #3f2783;
    width: 35px;
    text-align: center;
    /* background-color: #ebe9f2; */
    opacity: 0.9;
    border-radius: 28px;
    font-size: 12px;
    top: -22px;
    /* margin-left: 3px; */
    font-weight: 600;
}


.range-slider:hover .range_tootip {
    color: #fff;
    background-color: #3f2783;
}

.range_slider_box .happy_face,
.range_slider_box .sad_face {
    max-width: 40px;
}

.range_slider_box .happy_face {
    margin-right: 10px;
}

.range_slider_box .sad_face {
    margin-left: 10px;
}

.range_slider_box .happy_face img,
.range_slider_box .sad_face img {
    max-width: 100%;
}

.range_slider_box {
    display: flex;
    align-items: center;
    flex-direction: row;
    align-content: center;
    margin-top: 5px;
    position: relative;
}

.range_slider_box .stable_txt,
.range_slider_box .unstable_txt {
    position: absolute;
    top: 22px;
    color: #3f2783;
}

.range_slider_box .stable_txt {
    width: 50%;
    text-align: left;
}

.range_slider_box .unstable_txt {
    width: 50%;
    text-align: right;
    right: 0;
}

.range_slider_box .tooltip_main {
    width: 98%;
    position: relative;
}

.range_slider_box .slider_color_inverse {
    position: absolute;
    z-index: 1;
    right: 0;
    top: 5px;
    display: block;
    height: 10px;
    border-radius: 5px;
    background-color: #ccc;
}

.range_slider_box .slider_color_front {
    position: absolute;
    z-index: 0;
    width: 100%;
    left: 0;
    top: 5px;
    display: block;
    height: 10px;
    border-radius: 5px;
    background-image: linear-gradient(to right, #40E0D0 0%, #FFE600 50%, #FF0000 100%);
    /* background-image: linear-gradient(to right,#FF0000 0%, #FFE600 50%, #40E0D0 100%); */
}

.range_slider_box1 .slider_color_front {
    position: absolute;
    z-index: 0;
    width: 100%;
    left: 0;
    top: 5px;
    display: block;
    height: 10px;
    border-radius: 5px;
    /* background-image: linear-gradient(to right,#40E0D0 0%, #FFE600 50%, #FF0000 100%); */
    background-image: linear-gradient(to right, #FF0000 0%, #FFE600 50%, #40E0D0 100%);
}
.range_slider_box1 .happy_face,
.range_slider_box1 .sad_face {
    max-width: 40px;
}

.range_slider_box1 .happy_face {
    margin-right: 10px;
}

.range_slider_box1 .sad_face {
    margin-left: 10px;
}

.range_slider_box1 .happy_face img,
.range_slider_box1 .sad_face img {
    max-width: 100%;
}

.range_slider_box1 {
    display: flex;
    align-items: center;
    flex-direction: row;
    align-content: center;
    margin-top: 5px;
    position: relative;
}

.range_slider_box1 .stable_txt,
.range_slider_box1 .unstable_txt {
    position: absolute;
    top: 22px;
    color: #3f2783;
}

.range_slider_box1 .stable_txt {
    width: 50%;
    text-align: left;
}

.range_slider_box1 .unstable_txt {
    width: 50%;
    text-align: right;
    right: 0;
}

.range_slider_box1 .tooltip_main {
    width: 98%;
    position: relative;
}

.range_slider_box1 .slider_color_inverse {
    position: absolute;
    z-index: 1;
    right: 0;
    top: 5px;
    display: block;
    height: 10px;
    border-radius: 5px;     
    background-color: #ccc;
}
/* .range_tootip.range_tootip_knee {
    top: 12px;
    margin-left: -54px;
    width: 27%;
} */


