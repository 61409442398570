.tabslider_content {
    background: rgba(63, 39, 131, 0.1) !important;
    border: 2px solid rgba(63, 39, 131, 0.5);
    border-radius: 20px;
    height: 55px;
    margin: 0 0 40px 0px;
    max-width: 360px;
}

.range {
    position: relative;
    height: 55px;
    background: transparent !important;
}

.range input {
    width: 100%;
    position: absolute;
    top: 0;
    height: 57px;
    -webkit-appearance: none;
    z-index: 2;
    opacity: 0;
    background: transparent;
}

.range_labels {
    margin: -57px -2px 0px;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: space-between;
}

.range_labels li {
    position: relative;
    float: left;
    text-align: center;
    color: #b2b2b2;
    font-size: 14px;
    cursor: pointer;
    color: rgba(63, 39, 131, 0.5);
    font-size: 18px;
    width: 100%;
    text-align: center;
    height: 55px;
    line-height: 55px;
    border: 2px solid transparent;
    border-radius: 20px;
    margin-top: 0;
}

.range_labels li span {
    position: absolute;
    display: block;
    text-align: initial;
    width: 179px;
    color: #3F2783;
    font-size: 13px;
    top: 65px;
    line-height: 16px;
    opacity: 0;
    margin-left: 15px;
    visibility: hidden;
}

.score_range_slider>label {
    font-size: 14px;
    line-height: 28px;
    font-weight: 600;
    display: block;
    margin-bottom: 10px;
    color: #000;
    min-height: 28px;
}

.labels {
    color: #3F2783 !important;
}