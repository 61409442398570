.vm_sidebar .vm_item {
    color: rgba(63, 39, 131, 0.5) !important;
    font-weight: 700;
    /* margin-bottom: 14px; */
    padding-left: 0 !important;
    line-height: 18px;
    width: 100%;
}
.vm_sidebar {
    background-color: transparent !important;
}

@media only screen and (min-width: 1200px) {
    .vm_sidebar {
        overflow-y: auto;
        max-height: 100%;
        width: 250px;
        padding-top: 66px;
    }
    .vm_sidebar .vm_sidebar_inner {
        flex-direction: column;
        width: 100%;
        max-height: 100% !important;
    }
}
@media only screen and (max-width: 1199px) {
    .vm_sidebar .vm_item {
        width: auto;
        margin-right: 20px;
    }
    .vm_sidebar .vm_sidebar_inner {
        flex-wrap: wrap;
    }
}