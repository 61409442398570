.toaster {
    position: fixed;
    top: 70px;
    right: 20px;
    padding: 13px;
    /* background-color: #333; */
    box-shadow: 0 0 10px #000;
    color: #fff;
    border-radius: 5px;
    transition: all 0.2s ease-in-out;
    font-size: 14px;
  }
  
  .toaster-hidden {
    opacity: 0;
    transform: translateY(50px);
  }
  
  .toaster-visible {
    opacity: 1;
    transform: translateY(0);
    z-index: 2;
  }