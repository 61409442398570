.accordion_sidebar {
    display: inline-block;
    vertical-align: top;
    width: 250px;
    margin-right: 45px;
}
.accordion_tab_content {
    width: calc(100% - 300px);
    display: inline-block;
    vertical-align: top;
}
.accordion_tabpanel {
    position: relative;
    width: 100%;
    height: auto;
    border: 2px solid #3f2783;
    border-radius: 20px;
    margin-bottom: 40px;
}
.accordian_sub_head{
    font-size: 24px;
    font-weight: 600;
    padding-bottom: 20px;
    margin-top: 5px;
}
@media only screen and (max-width: 1199px) {
    .accordion_sidebar {
        display: block;
        width: 100%;
        margin-right: 0;
    }
    .accordion_tab_content {
        width: 100%;
        display: block;
    }
} 