.pain_screen .front-img svg .path-hover:hover, .pain_screen .back-img svg .path-hover:hover {
    fill: red;
}
.pain-imagebox {
    border: 2px solid rgba(63, 39, 131, 0.5);
    box-sizing: border-box;
    border-radius: 20px;
    max-width: 480px;
    float: right;
    padding: 20px 10px;
    text-align: center;
}
.pain-imagebox label {
    font-size: 14px;
    line-height: 28px;
    font-weight: 600;
    display: block;
    margin-bottom: 20px;
}
.imagebox-top {
    display: flex;
    align-content: space-evenly;
}
.imagebox-top span {
    color: #3F2783;
    font-size: 14px;
    font-weight: 600;
    display: inline-block;
    margin-top: 10px;
}

@media only screen and (max-width: 900px) {
    .pain-imagebox {
        float: none;
    }
}