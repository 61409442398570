.function_formtop {
    border: 2px solid #3f2783;
    padding: 15px;
    border-radius: 15px;
    margin: 30px 0;
}
.function_head, .function_note{
    margin-bottom: 15px;
}
.function_head label {
    color: #000;
    margin-bottom: 0;
}
.function_head span {
    font-size: 12px;
}
.function_note p {
    font-size: 12px;
}
.function_note p strong{
    font-size: 14px;
}
.function_screen .label_back label{
    color: #000;
}