.sacs_formtop {
    border: 2px solid #3f2783;
    padding: 15px;
    border-radius: 15px;
    margin: 30px 0;
}
.sacs_head, .sacs_note{
    margin-bottom: 15px;
}
.sacs_head label {
    color: #000;
    margin-bottom: 0;
}
.sacs_head span {
    font-size: 12px;
}
.sacs_note p {
    font-size: 12px;
}
.sacs_note p strong{
    font-size: 14px;
}
.sacs_screen .label_back label{
    color: #000;
}
.sacs_screen .label-title {
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 25px;
    font-weight: 600;
}
.sacs_screen .label_style label {
    font-weight: 400;
}
.sacs_screen .lab_title{
    color: #000;
}
.sacs_screen .lab_title small{
    display: block;
    font-weight: 400;
    margin: 6px 0;
}